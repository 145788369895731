.end-section {
  width: 100%;
  max-width: 100%;
  padding: 64px 32px 128px 32px;
  display: flex;
  justify-content: center;
  align-tracks: center;
}

.end-section-cta,
.end-section-cta:visited,
.end-section-cta:focus,
.end-section-cta:active,
.end-section-cta:hover {
  --background: black !important;
  --border-radius: 8px !important;
}
/* 
.end-section-cta > button {
  --border-radius	: 20 !important;
} */

.vg-tv-end-section-cta-lg,
.vg-tv-end-section-cta-lg:focus,
.vg-tv-end-section-cta-lg:active,
.vg-tv-end-section-cta-lg:hover {
  --background: blue !important;
  --border-radius: 8px !important;

  transform: scale(1.3);
}
