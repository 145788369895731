.top-header-fb-logo {
  width: 118px;
}

.top-header-button-left {
  width: 35px;
  height: 35px;
  margin: 5px 10px;
  background-color: #e4e3eb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.top-header-button-right {
  width: 35px;
  height: 35px;
  background-color: #e4e3eb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top-header-button-icon {
  display: block;
  width: 21px;
  height: 21px;
}

.top-header-respond-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-top: 1.3px solid #ebebeb;
  border-bottom: 1.3px solid #ebebeb;
  padding: 0 12px;
  background-color: #fff;
  margin: 0;
  margin-bottom: 1rem;
}
.top-header-respond-avatar {
  margin: 15px 9px 15px 0;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.top-header-respond-input {
  display: flex;
  align-items: center;
  text-align: center;
  height: 37px;
  flex-grow: 1;
  border: 1.5px solid #dbe4ed;
  border-radius: 60px;
  /* flex-grow: 1; */
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding-left: 16px;
  font-family: "SF Pro Text";
}
.top-header-actions {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;

  padding: 8px 0;
  border-bottom: 10px solid #c8cdd2;
}

.top-header-actions-label {
  color: #3c3c3c;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
  margin-left: 8px;
  font-family: "SF Pro Text";
  padding: 6px 0;
}
