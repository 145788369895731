.organic-item {
  --padding-start: 0;
  --inner-padding-end: 0;

  border-bottom: 8px solid #c9ccd1;

  ion-card {
    margin: 0;
    box-shadow: none;
    width: 100%;

    ion-card-header {
      padding: 0;

      h2 {
        font-weight: 600;
      }

      .globe-icon {
        margin-top: 3px;
      }

      ion-toolbar {
        --ion-toolbar-background: transparent;

        ion-item {
          --padding-start: 10px;
          --inner-border-width: 0;

          ion-avatar {
            margin: 2px 10px 2px 0;
          }

          ion-label {
            .meta {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }
      }
    }

    ion-card-content {
      padding: 0;

      img {
        width: 100%;
        display: block;
      }

      ion-label {
        padding: 0 10px 10px 10px;
        white-space: normal !important;
        color: #1c1e21 !important;
        font-size: 14px !important;

        .hashtag {
          color: #456ca0;
        }
      }

      .action {
        display: flex;
        align-items: center;
        gap: 50px;

        padding: 10px;
        background-color: #eee;

        ion-label {
          padding: 0;
          font-weight: bold;

         
        }

        ion-button {
          --padding-start: 20px;
          --padding-end: 20px;
        }

      }
    }

    ion-footer {
      ion-toolbar {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        --border-width: 0 !important;

        ion-label {
          display: flex !important;
          align-items: center;
          --color: #616770 !important;

          ion-icon {
            margin-right: -4px;
            width: 18px;
            height: 18px;

            border: 2px solid #fff;
            border-radius: 11px;

            &:last-of-type {
              margin-right: 5px;
            }
          }
        }
      }

      ion-buttons {
        padding: 5px 20px;
        justify-content: space-between;

        ion-button {
          font-size: 14px;
        }

        ion-label {
          --color: #616770 !important;
          font-weight: 600;

        }
        
        .liked {
          --color: #5890FF !important;
        }
        
        .like-animation {
          animation: grow .2s;
          animation-delay: 0.2s;
        }

        @keyframes grow {
          from {
            transform: scale(1) rotate(0);
          }
          to {
            transform: scale(1.2) rotate(-10deg);
          }
        }

        ion-img {
          margin-right: 8px;
          // margin-top: 4px;
          height: 20px;
        }
      }
    }
  }
}

 
