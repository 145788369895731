html,
body {
  background: white;
  color: black;
}
* {
  margin: 0;
}
.question {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  font-family: "San Francisco" !important;
}
.answer {
  font-size: 0.9rem;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 600;
  opacity: 0.8;
  margin-top: 1rem;
  text-align: center;
  font-family: "San Francisco" !important;
}
.welcome-description {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 600;
  opacity: 0.8;
  margin-top: 1rem;
  text-align: center;
  font-family: "San Francisco" !important;
}
.pre-shop-container {
  background: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 4rem 2rem !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow: scroll;
}
