.fb-section-title {
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.41px;
  color: #050505;
}

.fb-toggle-section-icon {
  width: 24px;
  height: 24px;
}

.fb-section-content-p,
.fb-section-content-cta,
.fb-section-content-p-secondary,
.fb-section-content-p-sm-secondary {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #050505;
}

.mb-1 {
  margin-bottom: 1rem;
}

.fb-section-content-cta {
  color: #1763cf;
}

.fb-section-content-p-secondary {
  font-weight: 400;
  color: #65676b;
}

.fb-section-content-p-sm-secondary {
  font-size: 12px;
  line-height: 16px;
  color: #8e8e8e;
}
.fb-section-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #1763cf;
}

.rotate-180 {
  transform: rotate(180deg);
}

.fb-section-see-all-bttn {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.24px;

  color: #1763cf;
}
