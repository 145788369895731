.community-card {
  position: relative;
  width: 163.5px !important;
  height: 163.5px !important;
  max-width: 163.5px !important;
  max-height: 163.5px !important;
  min-width: 163.5px !important;
  min-height: 163.5px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  margin: 0 6.5px 0 0 !important;
}

.community-card-content {
  position: relative;
  width: 163.5px;
  height: 163.5px;
  max-width: 163.5px;
  max-height: 163.5px;
  min-width: 163.5px;
  min-height: 163.5px;
}

.community-img {
  width: 163.5px;
  height: 163.5px;
  object-fit: cover;
}

.community-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 38px;
  width: 163.5px;

  background: linear-gradient(
    180deg,
    rgba(38, 38, 38, 0.0001) 3.45%,
    rgba(0, 0, 0, 0.14) 95.93%
  );
}

.community-username {
  position: absolute;
  height: 14px;
  left: 12px;
  bottom: 8.5px;

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5),
    0px 0px 8px rgba(0, 0, 0, 0.860168);
}

.horizontal-scroll {
  display: flex;

  align-items: center;
  overflow: scroll;
  scroll-behavior: smooth;
}
