.product-card-img-wrapper {
  /* width: 163px;
  height: 163px;
  min-width: 163px;
  min-height: 163px;
  max-width: 163px;
  max-height: 163px; */
  position: relative;
  aspect-ratio: 1 / 1;
}
.product-card-img {
  width: 100%;
  height: 100%;

  border: 0.5px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  object-fit: cover;
}

.product-info-wrapper {
  padding: 8px 4px 0 4px !important;
}

.product-card-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #050505;
  width: calc(100% - 24px);
}
.product-card-price {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
}
.product-card-discount {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0095f6;
  margin-right: 4px;
}
.line-through {
  text-decoration-line: line-through;
}
.product-bookmark-icon {
  width: 16px;
  height: 16px;
}
.product-sale-badge {
  padding: 4px 6px;
  background-color: #262626;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  position: absolute;
  bottom: 8px;
  left: 8px;
  border-radius: 4px;
}

.ig-product-grow-animation {
  animation: grow 0.3s;
  animation-timing-function: ease-in-out;
}

.row-no-wrap {
  flex-wrap: nowrap !important;
}

@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
