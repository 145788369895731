.comments-item {
  padding-top: 10px;

  ion-item {
    width: fit-content;
  }

  .avatar {
    margin-top: 10px;
    align-self: flex-start;
  }

  .comment-box {
    margin-top: 0;

    .meta {
      white-space: normal;
      border-radius: 18px;
      padding: 9px 12px 6px;
      background-color: #f0f2f5;
      color: #050505;
      h2 {
        font-size: 13px;
        letter-spacing: -0.08px;
        font-weight: 600;
      }

      p {
        margin-top: 5px;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.24px;
      }
    }

    footer {
      padding: 2px 10px;
      display: flex;
      gap: 15px;

      font-size: 13px;
      line-height: 16px;

      letter-spacing: -0.08px;

      color: #65676b;

      *:nth-child(n + 2) {
        font-weight: 600;
      }
    }
  }
}
