.fb-product-image {
  width: 253px;
  height: 338px;
  min-width: 253px;
  min-height: 338px;
  max-width: 253px;
  max-height: 338px;
}

.fb-product-image-wrapper {
  border-radius: 0 !important;
  border: none !important;
  min-width: auto !important;
  min-width: 253px !important;
  margin: 0 !important;
  margin-right: 6px !important;
}

.fb-product-tag {
  padding: 4px 8px;
  border: 1px solid #2d88ff;
  color: #2d88ff;
  border-radius: 4px;
  height: auto;
  min-height: auto;
  min-width: auto;
  margin-right: 6px;
}
.fb-product-tag-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  letter-spacing: -0.08px;
}
.fb-product-price {
  margin-top: 4px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  color: #050505;
}
.fb-product-rating {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
.fb-product-reviews {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: -0.15px;

  color: #737373;
}
.fb-product-name {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.32px;
  margin-bottom: 4px;

  color: #000000;
}

.fb-share-icon {
  width: 20px;
  height: 20px;
}

.fb-bookmark-icon {
  width: 20px;
  height: 20px;
  margin-right: 24px;
}

.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.m-16 {
  margin: 16 !important;
}
.p-16 {
  padding: 16 !important;
}
.m-6 {
  margin: 6 !important;
}
.p-6 {
  padding: 6 !important;
}

.fb-product-color-image-wrapper {
  margin-right: 8px;
  position: relative;
  border-radius: 6px;
  max-width: 44px;
  max-height: 44px;
  border: 2px solid #e4e6eb;
  overflow: hidden;
}
.fb-product-color-image-wrapper__active {
  border-color: #1877f2;
}

.fb-product-color-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fb-product-color-image img {
  border-radius: 6px !important;
  --border-radius: 6px !important;
}

.fb-product-size-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #e4e6eb;
  border-radius: 6px;
  margin-right: 8px;
}
.fb-product-size-wrapper__active {
  border-color: #1877f2;
}
.fb-product-size-wrapper__unavailable {
  border: 2px dashed rgba(0, 0, 0, 0.05);
}
.fb-product-size-wrapper__unavailable p {
  color: #bcc0c4;
}
.fb-product-size {
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  letter-spacing: -0.08px;
  color: #050505;
  text-transform: uppercase;
}

.fb-product-color-name {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;

  color: #737373;
}

.fb-product-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #050505;
}

.dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  background-color: white;
  opacity: 0.7;
}

:host(.button-solid) {
  --background: var(--ion-color-primary, #0095f6) !important;
}

.fb-button {
  padding: 10px 12px;
  height: 44px;
  background: #1b74e4 !important;
  color: white;
  border-radius: 6px;
  font-family: "SF Pro Text";
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.41px;
  width: 100%;
  text-transform: none;
  letter-spacing: 0 !important;
}

.fb-product-cta-bttns {
  /* padding: 1rem; */
  margin-top: 16px;
}

.fb-primary-cta {
  background: #1b74e4 !important;
  color: white;
}

.fb-secondary-cta {
  background: #e4e6eb !important;
  color: #050505;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: center;
}

.fb-cart-icon-button {
  width: 22px;
  height: 22px;
  margin-right: 6px;
}
.protection-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.protection-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.154px;
  color: #737373;
  margin-right: 4px;
}
.protection-link {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #1763cf;
}

.dropdown-icon {
  width: 12px;
  height: 12px;
}
.dropdown-value {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin-right: 4px;
}
.dropdown-label {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #737373;
}

.fb-product-size-guide-cta {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #1763cf;
}
