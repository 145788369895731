.store-reminder-avatar {
  width: 44px;
  height: 44px;
  border-radius: 999px;
  margin: 4px;
  margin-right: 12px;
  overflow: hidden;
}
.store-reminder-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #000000;
}
.store-reminder-username {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #737373;
}
.store-reminder-icon {
  width: 16px;
  height: 16px;
}
