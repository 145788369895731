.purchase-cta-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 8px 16px 8px 10px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.purchase-cta-img {
  width: 42px;
  height: 42px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  object-fit: cover;
  margin-right: 8px;
}

.purchase-cta-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #2b2b2b;
}
.purchase-cta-price {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  text-decoration-line: line-through;
}
.purchase-cta-discount {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #0095f6;
  margin-right: 4px;
}
.purchase-cta-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #0095f6;
}
