.vg-video-info-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.5rem;
}
.vg-video-info-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.5rem;
}
.vg-video-info-icon {
  width: 12px;
  height: 12px;
  margin-right: 0.25rem;
}

.vg-video-info-label-sm {
  margin-right: 1rem;
  font-size: 13px;
}

.vg-video-info-tag {
  height: 32px;
  background-color: #424244;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  margin-right: 0.5rem;
}
