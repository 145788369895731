@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

@font-face {
  font-family: "San Francisco";
  font-weight: 500;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
}

@font-face {
  font-family: "San Francisco";
  font-weight: 600;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
}

@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}

@font-face {
  font-display: swap;
  font-family: "Austin News Text Web";
  font-style: normal;
  font-weight: 400;
  src: url("http://localhost:3000/assets/fonts/AustinNewsText-Roman-Web.woff");
}

* {
  font-family: "San Francisco" !important;
  font-style: normal;
  letter-spacing: 0;
}

.app {
  ion-badge {
    top: 8px;
    left: calc(50%);
  }

  .facebook {
    .icon {
      width: 32px;
      height: 32px;
    }

    ion-tab-button[aria-selected="true"] {
      border-top: 2px solid var(--color-selected);
      margin-left: 6px;
      ion-img {
        margin-top: -2px;
      }
    }
  }

  .instagram {
    .icon {
      width: 24px;
      height: 24px;
    }
  }
}
