.feed-item-instagram {
  --padding-start: 0;
  --inner-padding-end: 0;
  --inner-border-width: 0;
  // --border-color: none;
  // --border-width: 0;

  &::part(native) {
    // border-bottom-width: 0px;
    // border-bottom-color: #c9ccd1;
  }

  margin-bottom: 15px;

  ion-card {
    width: 100%;
    margin: 0;
    box-shadow: none;

    ion-card-header {
      padding: 0;

      border-bottom: 1px solid #efefef;

      h2 {
        font-size: 14px;
        font-weight: 600;
      }

      .globe-icon {
        margin-top: 3px;
      }

      ion-toolbar {
        --ion-toolbar-background: transparent;

        ion-item {
          --padding-start: 10px;
          --inner-border-width: 0;

          ion-avatar {
            width: 32px;
            height: 32px;
            margin: 2px 10px 2px 0;
          }
        }
      }
    }

    ion-card-content {
      padding: 0;

      ion-label {
        padding: 0 10px 10px 10px;
      }

      .action {
        display: flex;
        align-items: center;
        gap: 50px;

        padding: 10px;
        background-color: #eee;

        ion-label {
          padding: 0;
          font-weight: bold;
        }

        ion-button {
          --padding-start: 20px;
          --padding-end: 20px;
        }
      }
    }

    ion-footer {
      ion-toolbar {
        --background: transparent;
        --border-width: 0 !important;

        ion-label {
          display: flex !important;
          align-items: center;
          --color: #8e8e8e !important;

          ion-avatar {
            margin-right: -8px;
            width: 22px;
            height: 22px;

            border: 2px solid #fff;
            border-radius: 11px;

            &:last-of-type {
              margin-right: 5px;
            }
          }
        }

        .likes-text {
          color: #000;
        }
      }

      ion-buttons {
        padding: 5px 0 0 5px;
        justify-content: space-between;

        ion-button {
          font-size: 14px;
        }

        ion-label {
          --color: #8e8e8e !important;
          font-weight: 600;
        }

        ion-img {
          margin-right: 8px;
          margin-top: 4px;
          height: 24px;
        }
      }
    }

    .comments {
      --dim-color: #8e8e8e;
      color: black;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      strong {
        margin-right: 5px;
      }

      .entries {
        .author {
          display: flex;
          align-items: baseline;
          > ion-label {
            text-overflow: ellipsis;
            overflow: hidden;

            display: -webkit-box !important;
            line-clamp: 2;
            box-orient: vertical;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
        & > * {
          padding-top: 5px;
        }
      }

      .more {
        color: var(--dim-color);
      }

      aside {
        display: flex;
        color: var(--dim-color);
        align-items: center;
        gap: 5px;

        ion-avatar {
          height: 24px;
          width: 25px;
        }
      }

      small {
        color: var(--dim-color);
      }
    }
  }
}
