html,
body {
  background: white;
  color: black;
}
* {
  margin: 0;
}

.modal-container > a {
  width: 100%;
}

.pre-modal-container {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  margin: 0;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
.title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.description {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  text-align: center;
}
.cta {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  background: black;
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.6rem;
  border: none;
  margin-top: 1rem;
  width: 100%;
  border: none;
  font-weight: bold;
  height: 3rem !important;
}
.cta:disabled {
  opacity: 0.2;
}

.instructions-cta,
.instructions-cta:focus,
.instructions-cta:active,
.instructions-cta:hover {
  --background: black !important;
  --border-radius: 8px !important;
  width: 100%;
  height: 52px;
  font-weight: bold;
  font-size: 1rem;
}
