.vg-tv-video-card-created-at {
  color: #a1a1a1;
  font-family: system-ui, blinkmacsystemfont, -apple-system, "Inter", "Segoe UI",
    robot, helvetica, arial, sans-serif;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 0.9rem;
  list-style: none;
  margin-top: 0.5rem;
}
.vg-tv-video-card-title {
  color: #e8e8e8;

  font-family: system-ui, blinkmacsystemfont, -apple-system, "Inter", "Segoe UI",
    roboto, helvetica, arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  max-width: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vg-tv-video-card-container {
  margin: 0 1rem 0 0 !important;
}
