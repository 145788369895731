@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

.feed {
  .instagram {
    padding: 10px;
    .logo {
      width: 116px;
    }

    .actions {
      width: 120px;
    }
  }

  .explore {
    bottom: 0;
    margin: 0;
    box-shadow: none;
    width: 100%;

    border-radius: 0;
    transition: opacity 0.5s ease-in-out;

    &.enter {
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
    }

    &.exit-active {
      opacity: 0;
    }

    ion-card-header {
      padding: 0;

      ion-item {
        --padding-start: 10px;
        --inner-border-width: 0;

        ion-avatar {
          margin: 2px 10px 2px 0;
        }

        ion-label {
          .meta {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }
}
