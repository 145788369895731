.brandid-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  padding-bottom: 0;

  &::part(native) {
    border-bottom-width: 8px;
    border-bottom-color: #c9ccd1;
  }

  > ion-card {
    margin: 0;
    box-shadow: none;
    width: 100%;

    ion-card-header {
      padding: 0;

      h2 {
        font-size: 17px;
        font-weight: 800;
      }

      .meta {
        font-size: 14px;
        color: #000;
      }

      ion-toolbar {
        --ion-toolbar-background: transparent;

        ion-item {
          --padding-start: 10px;
          --inner-border-width: 0;

          ion-label {
            .meta {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }
      }
    }

    ion-card-content.content {
      padding-inline-start: 0px;
      padding-inline-end: 0px;

      padding-bottom: 0;

      > ion-card {
        margin-top: 0px;
        box-shadow: none;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));

        ion-card-content {
          padding-bottom: 15px;
        }

        .background-image {
          height: 164px;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }

        aside {
          margin-top: -30px;
          margin-right: -10px;
          display: flex;
          justify-content: flex-end;
          gap: 10px;

          ion-button {
            --padding-start: 15px;
            --padding-end: 15px;

            font-size: initial;
            ion-img {
              width: 20px;
            }
          }
        }

        ion-label {
          margin-top: -10px;
          h2 {
            font-size: 17px;
            font-weight: 800;
            color: #000;
          }

          .meta {
            margin: 5px 0;
            font-size: 12px;
            color: #666;
          }
        }

        .stats {
          padding: 15px 0;
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: 12px;
          color: #333;

          ion-img {
            width: 20px;
          }

          > * {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

        ion-avatar {
          width: 75px;
          height: 75px;
          margin-top: -62px;
          margin-left: -5px;
          border: 3px solid #fff;
        }

        .cta-button {
          font-size: 15px;
          ion-img {
            margin-left: 10px;
            width: 16px;
          }
        }
      }
    }
  }
}
