.fb-header {
  width: 100%;
  padding: 3px 16px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 999px;
  margin: 3px;
  overflow: hidden;
}

.verified-icon {
  width: 12px;
  height: 12px;
}

.back-icon {
  margin-right: 6px;
}

.cart-icon {
  margin-right: 18px;
}

.shop-name {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #000000;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}
