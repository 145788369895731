.fb-purchase-cta-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 8px 16px 8px 10px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
}

.fb-purchase-cta-img {
  width: 42px;
  height: 42px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  object-fit: cover;
  margin-right: 8px;
}

.fb-purchase-cta-name {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #050505;
}
.fb-purchase-cta-price {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.24px;
  text-decoration-line: line-through;
  color: #bcc0c4;
}
.fb-purchase-cta-discount {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #050505;
  margin-right: 4px;
}
.fb-purchase-cta-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: #1b74e4;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.1;
  letter-spacing: -0.24px;
  color: #ffffff;
}
