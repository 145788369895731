.banner-item {
  --padding-start: 10px;

  --inner-padding-end: 0;
  --inner-padding-top: 12px;
  --inner-padding-bottom: 15px;

  width: 100%;

  .avatar {
    // --border-radius: 4px;
    width: 40px;
    height: 40px;
  }

  header {
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0;
      font-weight: 600;
      font-size: 15px;
    }

    p {
      font-size: 13px;
      margin: 3px 0 0 0;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  ion-button {
    --padding-start: 20px;
    // --padding-end: 20px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    // --ion-color-base: #65676b !important;
    --border-color: #8a8d91;
    font-weight: 600;
    // --border-width: 1px;
    --border-style: solid;
    --color: #4b4c4f;
    --background: transparent;
    --background-hover: transparent;
    --background-focused: transparent;
    --background-activated: #8a8d91;
  }
}
