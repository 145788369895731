.reviews-rating {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;

  color: #000000;
  margin-left: 2px;
}
.reviews-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #737373;
  margin-left: 2px;
}

.star-size-md {
  width: 16px;
  height: 16px;
}

.star-size-sm {
  width: 12px;
  height: 12px;
}

.comment-img {
  overflow: hidden;
  max-width: 35px;
  border-radius: 4px;
  margin-right: 4px;
}

.comment-username {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;

  color: #000000;
}

.comment-verified {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
}

.comment-content {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #000000;
  width: calc(100% - 30px);
  margin-bottom: 8px;
}

.comment-likes {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
}

.comment-created-at {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  margin-right: 16px;
}

.comment-avatar {
  width: 32px;
  height: 32px;
  border-radius: 999px;
  margin-right: 10px;
  overflow: hidden;
}

.comment-avatar-sm {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  margin-right: 10px;
  overflow: hidden;
}
.comment-heart-icon {
  width: 12px;
  height: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-4 {
  margin-bottom: 4px;
}
