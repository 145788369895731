.slideshow-item {
  --padding-start: 0;
  --inner-padding-end: 0;

  &::part(native) {
    border-bottom-width: 8px;
    border-bottom-color: #c9ccd1;
  }

  &.enter {
    transform: translateY(-100%);
    transition: 0.3s linear;
  }

  &.enter-active {
    transform: translateY(0%);
  }

  > ion-card {
    margin: 0;
    box-shadow: none;

    ion-card-header {
      padding: 0;

      ion-toolbar {
        --ion-toolbar-background: transparent;

        ion-item {
          --padding-start: 10px;
          --inner-border-width: 0;

          ion-avatar {
            margin: 2px 10px 2px 0;
          }

          ion-label {
            .meta {
              display: flex;
              align-items: center;
              gap: 4px;

              .globe-icon {
                margin-top: 4px;
              }
            }
          }
        }
      }

      .description {
        padding: 0 10px;
        color: #050505;
        font-size: 15px;
      }
    }

    > ion-card-content {
      padding: 0;

      // gap: 1rem;
      display: flex;

      align-items: stretch;
      // gap: 30px;
      overflow: scroll;
      scroll-behavior: smooth;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      & > * {
        flex: 1;
        min-width: 150px;

        box-shadow: none;
        border: 1px solid #e4e6eb;
        border-radius: 8px;
        margin: 10px;
        gap: 10px;

        .close-action {
          --padding-start: 3px;
          --padding-end: 3px;
          --background: rgba(0, 0, 0, 0.4);
          --background-hover: rgba(0, 0, 0, 0.6);
          --background-activated: rgba(0, 0, 0, 0.6);
          position: absolute;
          top: 3px;
          right: 3px;
        }

        > img {
          display: block;
          object-fit: cover;
          width: 150px;
          height: 150px;
        }
        // This is for the ion-label
        --color: #050505;

        ion-card-content {
          padding: 0;
          height: 100%;

          .simple-item {
            background-color: #f0f2f5;
            padding: 10px;

            .title {
              font-size: 13px;
              font-weight: 500;
            }

            .subtitle {
              font-size: 12px;
              font-weight: 400;
            }
          }

          .button-item {
            display: flex;
            padding: 6px;
            flex-direction: column;
            gap: 10px;

            .title {
              font-size: 13px;
              font-weight: 500;
              text-align: center;
              padding-left: 5px;
            }

            ion-button {
              --color: #1877f2;
              --background: #e7f3ff;
              --border-radius: 6px;
              --box-shadow: none;
              --padding-top: 15px;
              --padding-bottom: 15px;
              ion-img {
                width: 16px;
                margin-left: 5px;
              }
            }
          }

          .full-item {
            display: flex;
            padding: 6px;
            flex-direction: column;
            // gap: 10px;
            height: 100%;
            justify-content: center;
            align-items: center;

            > img {
              position: absolute;
              top: 0;
              left: 0;
            }

            .title {
              position: relative;
              font-size: 15px;
              font-weight: 600;
              color: #fff;
            }

            .cta {
              position: relative;
            }
          }
        }
      }
    }

    ion-footer {
      ion-toolbar {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        --border-width: 0 !important;

        ion-label {
          display: flex !important;
          align-items: center;

          ion-icon {
            margin-right: -4px;
            width: 18px;
            height: 18px;

            border: 2px solid #fff;
            border-radius: 11px;

            &:last-of-type {
              margin-right: 5px;
            }
          }
        }
      }

      ion-buttons {
        padding: 10px;
        justify-content: space-between;
      }
    }
  }
}
