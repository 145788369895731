.fb-header {
  width: 100%;
  padding: 3px 16px;
}

.fb-avatar {
  width: 32px;
  height: 32px;
  border-radius: 999px;
  margin: 3px;
  overflow: hidden;
}

.fb-verified-icon {
  width: 16px;
  height: 16px;
}

.fb-back-icon {
  margin-right: 6px;
}

.fb-cart-icon {
  margin-right: 8px;
}

.fb-shop-info {
  margin-left: 8px;
}
.fb-shop-name {
  margin-right: 0.25rem;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.41px;
  color: #050505;
}
.fb-shop-followers,
.fb-shop-follow-cta {
  font-family: "SF Pro Text";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #65676b;
}
.fb-shop-follow-cta {
  color: #1763cf;
}
