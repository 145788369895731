.fb-about-title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #050505;
}

.fb-about-description {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #65676b;
  margin-top: 4px;
}
.fb-about-description-sm {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
}

.fb-about-avatar {
  width: 60px;
  height: 60px;
  border-radius: 999px;
  margin-right: 12px;
  overflow: hidden;
}

.fb-about-cta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;

  background: #e7f3ff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  letter-spacing: -0.24px;
  color: #1763cf;
}
