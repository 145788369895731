.vg-ads-section-dark {
  background: #2b2b2b;
  color: white;
  margin: 1rem 0 !important;
}
.vg-ads-section-light {
  background: #fff;
  color: #2b2b2b;
  margin: 1rem 0 !important;
}

.vg-ads-section-circle {
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  border-radius: 8px;
  background: #de0000;
  margin: 0 0.5rem;
}
.vg-ads-section-title {
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 0.5rem 0;
}

.a-remove-style {
  color: transparent !important;
}
