.vg-video-player-icon {
  border-radius: 7px;
  background-color: rgba(60, 60, 67, 0.6);
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 1, 1);
  width: 36px;
  height: 36px;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
}

.vg-video-player-ad {
  border-radius: 7px;
  background-color: rgba(60, 60, 67, 0.6);
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 1, 1);
  height: 36px;
  fill: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  padding: 0 0.75rem;
}

.vg-video-player-icon img,
.vg-video-player-ad img {
  width: 20px;
}

.vg-video-player-ad-popup-title {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
}
.vg-video-player-ad-popup-subtitle {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: rgba(235, 235, 245, 0.6);
}
