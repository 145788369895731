:root {
  --vg-tv-bg: #131315;
}

.vg-tv-container {
  background: var(--vg-tv-bg);
  color: white;
  height: 100%;
  overflow: auto;
}
.vg-feed-container {
  background: #e6e7e8;
  color: #2b2b2b;
  height: 100%;
  overflow: auto;
}
