@font-face {
  font-display: swap;
  font-family: Austin News Text Web;
  font-style: normal;
  font-weight: 400;
  src: url(http://localhost:3000/assets/fonts/AustinNewsText-Roman-Web.woff);
}

@font-face {
  font-display: swap;
  font-family: Austin News Text Web;
  font-style: normal;
  font-weight: 400;
  src: url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-Roman-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-Roman-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Text Web;
  font-style: italic;
  font-weight: 400;
  src: url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-Italic-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-Italic-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Text Web;
  font-style: normal;
  font-weight: 700;
  src: url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-Bold-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-Bold-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Text Web;
  font-style: italic;
  font-weight: 700;
  src: url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-BoldItalic-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsText/AustinNewsText-BoldItalic-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Deck Web;
  font-style: normal;
  font-weight: 400;
  src: local("AustinNewsDeck-Roman"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-Roman-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-Roman-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Deck Web;
  font-style: italic;
  font-weight: 400;
  src: url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-Italic-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-Italic-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Deck Web;
  font-style: normal;
  font-weight: 600;
  src: url(https://vgc.no/font-spesial/AustinNewsDeck/AustinNewsDeck-Medium-Web.woff2)
      format("woff2"),
    url(https://vgc.no/font-spesial/AustinNewsDeck/AustinNewsDeck-Medium-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Deck Web;
  font-style: italic;
  font-weight: 600;
  src: local("AustinNewsDeck-MediumItalic"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-MediumItalic-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-MediumItalic-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Deck Web;
  font-style: normal;
  font-weight: 700;
  src: local("AustinNewsDeck-Bold"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-Bold-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-Bold-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Austin News Deck Web;
  font-style: italic;
  font-weight: 700;
  src: local("AustinNewsDeck-BoldItalic"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-BoldItalic-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/AustinNewsDeck/AustinNewsDeck-BoldItalic-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Druk Text Web;
  font-style: normal;
  font-weight: 700;
  src: url(https://www.vg.no/vgc/font-spesial/frimand-fonts/druk201013/DrukText-Bold-Web.woff2)
      format("woff2"),
    url(https://www.vg.no/vgc/font-spesial/frimand-fonts/druk201013/DrukText-Bold-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Druk Condensed Web;
  font-style: normal;
  font-weight: 700;
  src: url(https://vgc.no/font-spesial/DrukCond/DrukCond-Super-Web.woff2)
      format("woff2"),
    url(https://vgc.no/font-spesial/DrukCond/DrukCond-Super-Web.woff)
      format("woff");
}
@font-face {
  font-display: swap;
  font-family: Druk Web;
  font-style: normal;
  font-weight: 700;
  src: url(https://vgc.no/font-spesial/Druk/Druk-Heavy-Web.woff2)
      format("woff2"),
    url(https://vgc.no/font-spesial/Druk/Druk-Heavy-Web.woff) format("woff");
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 100 900;
  src: url(https://www.vg.no/vgc/font-spesial/Inter/3.15/Inter-roman.var.subset.woff2)
    format("woff2");
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: italic;
  font-weight: 100 900;
  src: url(https://www.vg.no/vgc/font-spesial/Inter/3.15/Inter-italic.var.subset.woff2)
    format("woff2");
}

.vg-article-card-light {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #2b2b2b;
  background: white;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.vg-article-card-dark {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #2b2b2b;
  color: white;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.vg-article-card-title-container {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem !important;
  box-sizing: border-box;
}
.vg-article-card-title-container-2 {
  width: calc(100% - 0.5rem);
  min-width: calc(100% - 0.5rem);
  max-width: calc(100% - 0.5rem);
}

.vg-article-card-img {
  height: 100%;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.vg-article-card-subtitle {
  font-size: 14px;
  font-family: Inter, "Helvetica Neue", Helvetica, sans-serif !important;
  margin: 0.5rem;
  line-height: 1;
  margin-bottom: 0rem;
  color: #636465;
  font-weight: 700;
}

.vg-article-card-title {
  font-family: "Austin News Deck Web", serif !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
}
