.slideshow-item-instagram {
  --padding-start: 0;
  --inner-padding-end: ;
  --background: #fafafa;
  --inner-border-width: 0;

  &.enter {
    transform: translateY(-100%);
    transition: 0.3s linear;
  }

  &.enter-active {
    transform: translateY(0%);
  }

  > ion-card {
    margin: 0;
    padding-bottom: 10px;
    box-shadow: none;
    --background: transparent;

    ion-card-header {
      padding: 0;

      ion-toolbar {
        --background: transparent;

        ion-item {
          --padding-start: 10px;
          --inner-border-width: 0;
          --background: transparent;

          ion-avatar {
            margin: 2px 10px 2px 0;
          }

          ion-label {
            h2 {
              font-size: 16px;
              font-weight: 600;
              color: #000;
            }

            .meta {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }
      }

      ion-button {
        align-self: flex-start;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    > ion-card-content {
      padding: 0;

      // gap: 1rem;
      display: flex;

      align-items: center;
      // gap: 30px;
      overflow: scroll;
      scroll-behavior: smooth;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      & > * {
        flex: 1;
        min-width: 275px;

        box-shadow: none;
        border: 1px solid #e4e6eb;
        border-radius: 8px;
        margin: 10px;
        gap: 10px;

        .close-action {
          --padding-start: 3px;
          --padding-end: 5px;
        }

        // This is for the ion-label
        --color: #050505;

        .item-image {
          height: 275px;
          object-fit: cover;
        }

        ion-card-content {
          padding: 0;

          .simple-item {
            padding: 10px;

            ion-label {
              font-weight: bold;
            }
          }

          .full-item {
            background: #fff;
            display: flex;
            padding: 10px;
            flex-direction: column;
            gap: 10px;

            ion-label {
              // font-weight: bold;
              text-align: center;
            }

            ion-button {
              --color: #fff;
              --background: #0095f6;
              --border-radius: 6px;
              --box-shadow: none;
              --padding-top: 15px;
              --padding-bottom: 15px;
            }
          }
        }
      }
    }

    ion-footer {
      ion-toolbar {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        --border-width: 0 !important;

        ion-label {
          display: flex !important;
          align-items: center;

          ion-icon {
            margin-right: -4px;
            width: 18px;
            height: 18px;

            border: 2px solid #fff;
            border-radius: 11px;

            &:last-of-type {
              margin-right: 5px;
            }
          }
        }
      }

      ion-buttons {
        padding: 10px;
        justify-content: space-between;
      }
    }
  }
}
