.vg-tv-logo {
  width: 115px;
}
.vg-home-logo {
  width: 80px;
}

.vg-home-header-icon {
  width: 24px;
  height: 24px;
}
.vg-home-header-account-icon {
  width: 29px;
  height: 29px;
}
