.section-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #000000;
}

.toggle-section-icon {
  width: 12px;
  height: 12px;
}

.section-content-p,
.section-content-p-secondary,
.section-content-p-sm-secondary {
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #000000;
}

.section-content-p-secondary {
  color: #737373;
}

.section-content-p-sm-secondary {
  font-size: 12px;
  line-height: 16px;
  color: #8e8e8e;
}
.section-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #00376b;
}

.rotate-180 {
  transform: rotate(180deg);
}

.section-see-all-bttn {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: -0.15px;

  color: #0095f6;
}
