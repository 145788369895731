.fb-reviews-rating {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;

  color: #2b2b2b;
  margin-left: 2px;
}
.fb-reviews-count {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #65676b;

  margin-left: 8px;
}

.fb-star-size-md {
  width: 12px;
  height: 12px;
}

.fb-star-size-sm {
  width: 12px;
  height: 12px;
}

.fb-comment-dots-menu-icon {
  width: 16px;
  height: 16px;
}

.fb-comment-img {
  overflow: hidden;
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-right: 4px;
}

.fb-comment-username {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;

  color: #2b2b2b;
}

.fb-comment-verified {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #65676b;
  margin-top: 4px;
}

.fb-comment-content,
.fb-comment-content-more {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #2b2b2b;
  width: calc(100% - 30px);
  margin-bottom: 8px;
}

.fb-comment-content-more {
  color: #65676b;
  margin-left: 4px;
}
.fb-comment-like-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
.fb-comment-liked {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: #1877f2;
}

.fb-comment-likes {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
}

.fb-comment-created-at {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  margin-right: 16px;
}

.fb-comment-avatar {
  width: 32px;
  height: 32px;
  border-radius: 999px;
  margin-right: 10px;
  overflow: hidden;
}

.fb-comment-avatar-sm {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  margin-right: 10px;
  overflow: hidden;
}
.fb-comment-heart-icon {
  width: 12px;
  height: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-32 {
  margin-bottom: 16px;
}
.mb-4 {
  margin-bottom: 4px;
}
