.product-image {
  width: 253px;
  height: 338px;
  min-width: 253px;
  min-height: 338px;
  max-width: 253px;
  max-height: 338px;
}

.product-image-wrapper {
  border-radius: 0 !important;
  border: none !important;
  min-width: auto !important;
  min-width: 253px !important;
  margin: 0 !important;
  margin-right: 6px !important;
}

.product-tag {
  padding: 4px 6px;
  border: 1px solid #000000;
  border-radius: 4px;
  height: auto;
  min-height: auto;
  min-width: auto;
  margin-right: 6px;
}
.product-tag-label {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.product-price {
  margin-top: 6px;
  margin-bottom: 12px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.product-rating {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
.product-reviews {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: -0.15px;

  color: #737373;
}
.product-name {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;

  color: #000000;
}

.share-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.bookmark-icon {
  width: 24px;
  height: 24px;
}

.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.m-16 {
  margin: 16 !important;
}
.p-16 {
  padding: 16 !important;
}
.m-6 {
  margin: 6 !important;
}
.p-6 {
  padding: 6 !important;
}

.product-color-image-wrapper {
  padding: 3px;
  border: 1.76418px solid transparent;
  margin-right: 2px;
  position: relative;
  border-radius: 6.61568px;
  max-width: 52px;
  max-height: 52px;
}

.product-color-wrapper {
  max-width: 52px;
}

.product-color-image {
  width: 40px;
  height: 40px;
  object-fit: cover;

  border: 0.5px solid #dbdbdb;
  border-radius: 4px;
}
.product-color-image-wrapper_active {
  border-color: #000;
}

.product-color-name {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;

  color: #737373;
}

.dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: white;
  opacity: 0.7;
}

:host(.button-solid) {
  --background: var(--ion-color-primary, #0095f6) !important;
}

.insta-button {
  padding: 13px 18px;
  height: 44px;
  background: #0095f6 !important;
  color: white;
  border-radius: 8px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-transform: none;
  letter-spacing: 0 !important;
}

.product-cta-bttns {
  padding: 1rem;
}

.primary-cta {
  background: #0095f6 !important;
  color: white;
}

.secondary-cta {
  background: transparent !important;
  color: #000;
  border: 1px solid #dbdbdb;
}

.protection-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.protection-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.154px;
  color: #737373;
  margin-right: 4px;
}
.protection-link {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #00376b;
}

.dropdown-icon {
  width: 12px;
  height: 12px;
}
.dropdown-value {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin-right: 4px;
}
.dropdown-label {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #737373;
}
